import camelcaseKeys from 'camelcase-keys';

import {
  SocketResponseMessageObject,
  SocketUserResponseObject,
} from 'types/socket/types';

export const getUsersObjectInCamelCase = (data: SocketUserResponseObject[]) => {
  const usersList = data.map((item) => camelcaseKeys(item));

  return usersList;
};

export const convertChatHistoryObject = (
  data: SocketResponseMessageObject[]
) => {
  const oldMessages = data.map((item) => ({
    mid: item.message_id,
    message: item.message,
    isOutgoing: item.sent_by === item.provider,
    isIncoming: item.sent_by === item.patient,
    timeStamp: item.timestamp,
    groupId: '',
  }));

  // Sort messages by timestamp in ascending order (oldest first)
  const sortedMessages = oldMessages.sort(
    (a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime()
  );

  return sortedMessages;
};

export const MESSAGE_QUEUE_KEY = 'messageQueue';

export const getStoredQueue = () => {
  const storedQueue = localStorage.getItem(MESSAGE_QUEUE_KEY);

  return storedQueue ? JSON.parse(storedQueue) : [];
};

export const updateMessageQueue = (newQueue: string[]) => {
  localStorage.setItem(MESSAGE_QUEUE_KEY, JSON.stringify(newQueue));
};
