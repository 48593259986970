import React, {
  createContext,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { FullHeightModal } from 'components/common/FullViewOvelayModal/FullViewOvelayModal';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Tooltip } from 'components/common/tooltip/ToolTip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import {
  ConfigurationModalProps,
  useConfigurationChangeModal,
} from 'hooks/e-prescribe/useConfigurationChangeModal';
import { transformPrescription } from 'pages/app/patients/[id]/prescriptions/prescribe/common';
import { hormoneReducerNew } from 'reducers/prescription-reducer';
import { PatientService } from 'services/patient';
import { PatientPrescriptionService } from 'services/prescription';
import { PatientDetailsContextType, StoredHormoneDetails } from 'types/patient';
import { OpenWarningPopupType } from 'types/patient/e-prescribe';
import { TitrationConfigType } from 'types/patient/prescription';
import {
  HormoneNames,
  PrescribedObjectType,
  ReducerActionTypeNew,
} from 'types/prescriptionTypes';
import { normalizeString } from 'utils/common';

import { CartView } from './CartView';
import { HormoneSelection } from './HormoneSelection';

type EPrescribeUtilContextType = {
  renderModal?: (config: ConfigurationModalProps) => void;
  Modal?: () => JSX.Element;
  switchHormone?: (hormoneName: HormoneNames) => void;
};

export const EPrescribeUtilContext = createContext<EPrescribeUtilContextType>(
  {}
);
export function HormoneListing() {
  const { id: patientId, gender } =
    useOutletContext<PatientDetailsContextType>();
  const [searchParams] = useSearchParams();
  const [titrationType] = useState(searchParams.get('titrationType'));
  const { Modal, renderModal } = useConfigurationChangeModal();

  const [showHormoneDetails, setShowHormoneDetails] = useState<{
    state: boolean;
    hormoneDetails: StoredHormoneDetails | undefined;
  }>({
    state: false,
    hormoneDetails: undefined,
  });
  const initialState: PrescribedObjectType[] = JSON.parse(
    (sessionStorage.getItem(`hormones${patientId}`) as string) ?? '[]'
  );
  const [prescribedHormones, dispatch] = useReducer(
    hormoneReducerNew,
    initialState
  );
  const [openWarningPopup, setOpenWarningPopup] =
    useState<OpenWarningPopupType>({
      state: false,
    });

  const [isPrescribeActive, setIsPrescribeActive] = useState(
    titrationType ? false : !initialState.length
  );

  const [excludedHormones, setExcludedHormones] = useState<string[]>([]);

  const getHormonesQuery = useQuery(
    [QueryKeys.Hormones, gender],
    () => PatientService.getPatientMedication(gender || ''),
    { enabled: !!gender }
  );

  const topicalHormones = useMemo(
    () =>
      getHormonesQuery?.data?.data
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (i) =>
            i.deliveryMethodAndSig[0].deliveryMethod === 'Oral Syringe' ||
            i.deliveryMethodAndSig[0].deliveryMethod ===
              'Dose applicator (Oral Syringe)'
        ),
    [getHormonesQuery?.data?.data]
  );

  const oralHormones = useMemo(
    () =>
      getHormonesQuery?.data?.data
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (i) =>
            i.deliveryMethodAndSig[0].deliveryMethod === 'Oral Tablets' ||
            i.deliveryMethodAndSig[0].deliveryMethod === 'Oral Capsules'
        ),
    [getHormonesQuery?.data?.data]
  );

  const injectableHormones = useMemo(
    () =>
      getHormonesQuery?.data?.data
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((i) => i.deliveryMethodAndSig[0].deliveryMethod === 'Vial'),
    [getHormonesQuery?.data?.data]
  );

  const getTitrationRxConfigQuery = useQuery(
    [
      QueryKeys.TitrationRxConfig.listing({
        titrationType: titrationType as string,
      }),
    ],
    () =>
      PatientPrescriptionService.getTitrationRxConfig(
        titrationType as TitrationConfigType
      ),
    {
      refetchOnMount: false,
      enabled: !!patientId && !!titrationType,
      retry: false,
      select(data) {
        return data?.data;
      },
      onSuccess() {
        setShowHormoneDetails({ state: true, hormoneDetails: {} });
        setIsPrescribeActive(false);
      },
    }
  );

  const checkAlreadyPrescribedItem = useCallback(
    ({ name, family }: PrescribedObjectType) => {
      let index = -1;
      let isRepeated = false;
      const existingHormoneDetails = {
        name: '',
        family: family ?? '',
        newHormoneName: name ?? '',
        id: '',
      };

      // eslint-disable-next-line no-plusplus
      for (let i = prescribedHormones.length - 1; i >= 0; i--) {
        if (prescribedHormones[i].name === name) {
          index = i;
          break;
        }
      }
      if (family && index === -1) {
        if (
          prescribedHormones?.some((item) => {
            if (item?.family === family) {
              existingHormoneDetails.name = item.name || '';
              existingHormoneDetails.id = item.id || '';
            }

            return item?.family === family;
          })
        ) {
          isRepeated = true;
        }
      }

      return { index, isRepeated, existingHormoneDetails };
    },
    [prescribedHormones]
  );

  const addNewPrescription = useCallback(
    async (prescribedItem: PrescribedObjectType) => {
      const { index, isRepeated, existingHormoneDetails } =
        checkAlreadyPrescribedItem(prescribedItem);

      if (isRepeated && existingHormoneDetails?.name) {
        setOpenWarningPopup({
          state: true,
          details: existingHormoneDetails,
          replaceItem: { ...prescribedItem },
        });

        return;
      }
      setIsPrescribeActive(false);

      let coveredUnderSubscription = 'false';
      if (prescribedItem.coveredUnderSubscription) {
        coveredUnderSubscription = prescribedItem?.coveredUnderSubscription;
      }
      if (index > -1) {
        dispatch({
          type: ReducerActionTypeNew.UPDATE_HORMONE,
          payload: {
            hormoneObject: {
              ...prescribedItem,
              id: prescribedItem.id,
              coveredUnderSubscription,
            },
            index,
          },
        });
        if (!prescribedItem.isFromTitration) {
          sessionStorage.setItem(
            `hormones${patientId}`,
            JSON.stringify([
              ...prescribedHormones.map((hormone) => {
                if (prescribedItem.id === hormone.id) {
                  return {
                    ...prescribedItem,
                    id: prescribedItem.id,
                    coveredUnderSubscription,
                  };
                }

                return hormone;
              }),
            ])
          );
        }
      } else {
        dispatch({
          type: ReducerActionTypeNew.ADD_HORMONE,
          payload: {
            hormoneObject: {
              ...prescribedItem,
              id: prescribedItem?.id,
              coveredUnderSubscription,
            },
            index: -1,
          },
        });
        if (!prescribedItem.isFromTitration) {
          sessionStorage.setItem(
            `hormones${patientId}`,
            JSON.stringify([
              ...prescribedHormones,
              {
                ...prescribedItem,
                id: prescribedItem?.id,
                coveredUnderSubscription,
              },
            ])
          );
        }
      }
    },
    [checkAlreadyPrescribedItem, patientId, prescribedHormones]
  );

  const removePrescribedItem = (index: number, id: string) => {
    dispatch({
      type: ReducerActionTypeNew.DELETE_HORMONE,
      payload: { hormoneObject: { refills: 0 }, index },
    });
    prescribedHormones.some((item) => {
      let successful = false;
      if (item.id === id) {
        if (item.isFromTitration) {
          successful = true;
          setExcludedHormones([...excludedHormones, id]);
        }
      }

      return successful;
    });

    sessionStorage.setItem(
      `hormones${patientId}`,
      JSON.stringify([
        ...prescribedHormones.filter((hormone) => id !== hormone.id),
      ])
    );
  };

  function replacePrescription(
    index: number,
    hormoneObject?: PrescribedObjectType
  ) {
    dispatch({
      type: ReducerActionTypeNew.UPDATE_HORMONE,
      payload: {
        hormoneObject: {
          ...hormoneObject,
        },
        index,
      },
    });

    sessionStorage.setItem(`hormones${patientId}`, JSON.stringify([]));
  }
  const alreadyFetchedIds: string[] = [];

  const addTitrationPrescriptions = useCallback(() => {
    if (!getTitrationRxConfigQuery?.data) return;
    getTitrationRxConfigQuery?.data?.forEach((item) => {
      if (alreadyFetchedIds.includes(item?.medicationId)) return;
      alreadyFetchedIds.push(item?.medicationId);
      addNewPrescription(transformPrescription({ ...item }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titrationType, getTitrationRxConfigQuery?.data]);

  const switchHormone = useCallback(
    (hormoneName: HormoneNames) => {
      const hormone = getHormonesQuery?.data?.data.find(
        (h) => normalizeString(h.name) === normalizeString(hormoneName)
      );

      if (hormone?.id) {
        setShowHormoneDetails({
          state: true,
          hormoneDetails: {
            id: hormone.id,
            name: normalizeString(hormone.name),
            coveredUnderSubscription: !!hormone.isCoveredUnderSubscription,
            isControlledSubstance: !!hormone.isControlledSubstance,
          },
        });
      }
    },
    [getHormonesQuery?.data?.data]
  );

  const modalWrapperProps = useMemo(
    () => ({ Modal, renderModal, switchHormone }),
    [Modal, renderModal, switchHormone]
  );

  useEffect(() => {
    if (titrationType) {
      addTitrationPrescriptions();
    }
  }, [
    titrationType,
    getTitrationRxConfigQuery?.data,
    addTitrationPrescriptions,
  ]);

  return (
    <SkeletonContent
      layout={[
        {
          className: 'flex flex-col gap-6',
          children: [
            {
              className:
                ' flex items-center bg-background-main h-16 rounded p-6 justify-between',
              children: [
                {
                  className: 'bg-background-main h-4 w-60 rounded',
                },
                {
                  className: 'bg-background-main h-8 w-40 rounded',
                },
              ],
            },
            {
              className:
                ' flex flex-col bg-background-main h-92 rounded p-6 gap-6',
              children: [
                {
                  className: 'bg-background-main h-4 w-60 rounded',
                },
                {
                  className: 'grid  grid-cols-12 gap-x-20 gap-y-6 mt-6',
                  children: [...Array(10)].map(() => ({
                    className:
                      'bg-background-main h-10 w-full rounded col-span-4',
                  })),
                },
              ],
            },
          ],
        },
      ]}
      isLoading={
        getHormonesQuery.isLoading ||
        !!(titrationType && getTitrationRxConfigQuery.isLoading)
      }>
      {() => (
        <div>
          <div className="flex  items-center justify-between rounded bg-primary-light p-6 py-4">
            <Typography color="white" variant="subtitle1">
              {`You have ${prescribedHormones.length} items in you cart`}
            </Typography>
            <Button
              onClick={() => {
                setIsPrescribeActive(false);
                setShowHormoneDetails({ state: true, hormoneDetails: {} });
              }}
              disabled={prescribedHormones.length < 1}
              className={classNames(
                'bg-[#6d6d6d] text-primary-contrastText !opacity-100 hover:bg-[#6d6d6d] active:bg-[#6d6d6d] ',
                {
                  'hover:bg-[#515151 !bg-[#515151] ':
                    prescribedHormones.length > 0,
                }
              )}
              color="secondary"
              data-tooltip-id="reviewButton">
              Review and Checkout
            </Button>
            {prescribedHormones.length < 1 && (
              <Tooltip theme="light" id="reviewButton">
                This cart is empty
              </Tooltip>
            )}
          </div>
          <div className="mt-6  flex flex-col gap-4 rounded bg-background-main p-6">
            <Typography variant="h3">
              Select the medicine to prescribe
            </Typography>
            <div
              className={classNames('mt-2 grid grid-cols-2 gap-x-6 gap-y-6', {
                '!grid-cols-3': !!injectableHormones?.[0]?.id,
              })}>
              {topicalHormones?.[0]?.id && (
                <div className=" rounded-3xl border border-[#414141]  ">
                  <Typography
                    variant="subtitle1"
                    className=" flex justify-center border-b border-[#414141] py-4 ">
                    Topical
                  </Typography>
                  <div className="  flex grow flex-col gap-y-6 py-6 px-8">
                    {topicalHormones?.map((item) => {
                      const alreadyPrescribed = prescribedHormones.some(
                        (p) => p.id === item.id
                      );

                      return (
                        <div>
                          <Button
                            data-tooltip-id={`hormone${item.id}`}
                            // color={getColor(item.name)}
                            onClick={() => {
                              setIsPrescribeActive(true);
                              setShowHormoneDetails({
                                state: true,
                                hormoneDetails: {
                                  id: item.id,
                                  name: normalizeString(item.name),
                                  coveredUnderSubscription:
                                    !!item.isCoveredUnderSubscription,
                                  isControlledSubstance:
                                    !!item.isControlledSubstance,
                                },
                              });
                            }}
                            className={classNames(
                              'flex w-full cursor-pointer items-center justify-center rounded bg-[#4d4d4d] !p-4 !py-2 !font-light shadow-sm hover:bg-primary-main ',
                              {
                                '!bg-primary-main': alreadyPrescribed,
                              }
                            )}>
                            <Typography color="white" variant="subtitle1">
                              {' '}
                              {item?.name}
                              {alreadyPrescribed && (
                                <Tooltip theme="light" id={`hormone${item.id}`}>
                                  This Rx is already in the cart.
                                </Tooltip>
                              )}
                            </Typography>
                          </Button>{' '}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {oralHormones?.[0]?.id && (
                <div className=" rounded-3xl border border-[#414141]  ">
                  <Typography
                    variant="subtitle1"
                    className=" flex justify-center border-b border-[#414141] py-4 ">
                    Capsules & Tablets
                  </Typography>
                  <div className="flex flex-col gap-y-6 py-6 px-8">
                    {oralHormones?.map((item) => {
                      const alreadyPrescribed = prescribedHormones.some(
                        (p) => p.id === item.id
                      );

                      return (
                        <div>
                          <Button
                            data-tooltip-id={`hormone${item.id}`}
                            // color={getColor(item.name)}
                            onClick={() => {
                              setIsPrescribeActive(true);
                              setShowHormoneDetails({
                                state: true,
                                hormoneDetails: {
                                  id: item.id,
                                  name: normalizeString(item.name),
                                  coveredUnderSubscription:
                                    !!item.isCoveredUnderSubscription,
                                  isControlledSubstance:
                                    !!item.isControlledSubstance,
                                },
                              });
                            }}
                            className={classNames(
                              'flex w-full cursor-pointer items-center justify-center rounded bg-[#4d4d4d] !p-4 !py-2 !font-light shadow-sm hover:bg-primary-main ',
                              {
                                '!bg-primary-main': alreadyPrescribed,
                              }
                            )}>
                            <Typography color="white" variant="subtitle1">
                              {' '}
                              {item?.name}
                              {alreadyPrescribed && (
                                <Tooltip theme="light" id={`hormone${item.id}`}>
                                  This Rx is already in the cart.
                                </Tooltip>
                              )}
                            </Typography>
                          </Button>{' '}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {injectableHormones?.[0]?.id && (
                <div className=" rounded-3xl border border-[#414141]  ">
                  <Typography
                    variant="subtitle1"
                    className=" flex justify-center border-b border-[#414141] py-4 ">
                    Injection
                  </Typography>
                  <div className="flex flex-col gap-y-6 py-6 px-8">
                    {injectableHormones?.map((item) => {
                      const alreadyPrescribed = prescribedHormones.some(
                        (p) => p.id === item.id
                      );

                      return (
                        <div>
                          <Button
                            data-tooltip-id={`hormone${item.id}`}
                            // color={getColor(item.name)}
                            onClick={() => {
                              setIsPrescribeActive(true);
                              setShowHormoneDetails({
                                state: true,
                                hormoneDetails: {
                                  id: item.id,
                                  name: normalizeString(item.name),
                                  coveredUnderSubscription:
                                    !!item.isCoveredUnderSubscription,
                                  isControlledSubstance:
                                    !!item.isControlledSubstance,
                                },
                              });
                            }}
                            className={classNames(
                              'flex w-full cursor-pointer items-center justify-center rounded bg-[#4d4d4d] !p-4 !py-2 !font-light shadow-sm hover:bg-primary-main ',
                              {
                                '!bg-primary-main': alreadyPrescribed,
                              }
                            )}>
                            <Typography color="white" variant="subtitle1">
                              {' '}
                              {item?.name}
                              {alreadyPrescribed && (
                                <Tooltip theme="light" id={`hormone${item.id}`}>
                                  This Rx is already in the cart.
                                </Tooltip>
                              )}
                            </Typography>
                          </Button>{' '}
                        </div>
                      );
                    })}
                  </div>{' '}
                </div>
              )}
            </div>
          </div>
          <EPrescribeUtilContext.Provider value={modalWrapperProps}>
            <FullHeightModal isOpen={showHormoneDetails.state}>
              {isPrescribeActive ? (
                <HormoneSelection
                  gender={gender}
                  addNewPrescription={addNewPrescription}
                  RxDetails={{
                    ...showHormoneDetails.hormoneDetails,
                  }}
                  onBack={() => {
                    setShowHormoneDetails({ state: false, hormoneDetails: {} });
                  }}
                />
              ) : (
                <CartView
                  titrationType={titrationType as TitrationConfigType}
                  prescribedHormones={prescribedHormones}
                  removePrescribedItem={removePrescribedItem}
                  onAddMore={() => {
                    setShowHormoneDetails({ state: false, hormoneDetails: {} });
                    setIsPrescribeActive(true);
                  }}
                  buttonLoading={false}
                  excludedHormones={excludedHormones}
                />
              )}
              <Modal />
            </FullHeightModal>
          </EPrescribeUtilContext.Provider>

          <Suspense>
            <ConfirmationModal
              isModalOpen={openWarningPopup.state}
              infoContent={
                <div>
                  <Typography variant="h4" color="primary" className="!inline">
                    {openWarningPopup?.details?.name}{' '}
                  </Typography>
                  <Typography variant="h4" className="!inline">
                    is already in this cart and hence adding
                  </Typography>
                  <Typography variant="h4" color="primary" className="!inline">
                    {' '}
                    {openWarningPopup?.details?.newHormoneName}{' '}
                  </Typography>{' '}
                  <Typography variant="h4" className="!inline">
                    is not permitted. Do you intend to replace it?
                  </Typography>
                  {/* <Typography variant="h4" color="primary" className="!inline">
                    {openWarningPopup?.details?.family}
                  </Typography>{' '}
                  <Typography variant="h4" className="!inline">
                    family. Please remove it to add this one.{' '}
                  </Typography> */}
                </div>
              }
              closeModal={() => {
                setOpenWarningPopup({ ...openWarningPopup, state: false });
                setTimeout(
                  () =>
                    setOpenWarningPopup({ state: false, details: undefined }),
                  300
                );
              }}
              confirmText="Replace"
              handleConfirm={() => {
                const replaceIndex = prescribedHormones.findIndex(
                  (p) => p.id === openWarningPopup?.details?.id
                );

                replacePrescription(
                  replaceIndex,
                  openWarningPopup?.replaceItem
                );
                setIsPrescribeActive(false);
                setOpenWarningPopup({ ...openWarningPopup, state: false });
                setTimeout(
                  () =>
                    setOpenWarningPopup({ state: false, details: undefined }),
                  300
                );
              }}
            />
          </Suspense>
        </div>
      )}
    </SkeletonContent>
  );
}
