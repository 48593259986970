import { useChat } from 'context/ChatProvider';

import { ChatFooter } from '../ChatFooter/ChatFooter';
import { ChatHeader } from '../ChatHeader/ChatHeader';
import { ChatMessageList } from '../ChatMessageList/ChatMessageList';
import { NoUserSelectedChatScreen } from '../NoUserSelectedChatScreen/NoUserSelectedChatScreen';

export function ChatWindow() {
  const {
    selectedUser,
    chats,
    socketMethods,
    isFetchingChats,
    totalMessagesCountInChat,
  } = useChat();

  if (!selectedUser.patientId) {
    return (
      <div className=" h-[calc(100vh-10rem)] flex-1 content-center p-4 text-center text-gray-500">
        <NoUserSelectedChatScreen />
      </div>
    );
  }

  const handleLoadMoreChats = () => {
    socketMethods?.openChatHistory({
      size: chats.length + 20,
      groupId: selectedUser.groupId || '',
      silentFetch: true,
    });
  };

  return (
    <div className="flex h-[calc(100vh-10rem)] flex-col border-l border-black">
      <ChatHeader userData={selectedUser} />
      <ChatMessageList
        chats={chats}
        isLastMessageInChat={
          !!(chats.length && chats.length === totalMessagesCountInChat)
        }
        isLoading={isFetchingChats}
        loadMoreMessages={handleLoadMoreChats}
        selectedPatient={selectedUser}
      />
      <div className="border-t border-black">
        <ChatFooter />
      </div>
    </div>
  );
}
