/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */

import { useEffect, useState, useRef, useCallback } from 'react';

import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import { useChat } from 'context/ChatProvider';
import { useSocketProvider } from 'context/SocketProvider/SocketProvider';
import {
  ChatUsersListObject,
  UsersEmitType,
  UsersType,
} from 'types/socket/types';
import { getUsersObjectInCamelCase } from 'utils/sockets/helper';

export const useSocket = () => {
  const currentUserRef = useRef<ChatUsersListObject | null>(null);
  const [isFetchingPatients, setIsFetchingPatients] = useState(false);
  const [connection, setConnection] = useState(false);

  const location = useLocation();
  const chatAllowedPathname = '/app/chat';
  const socketInstance = useSocketProvider();
  const currentGroupID = useRef<string | null>(null);

  const {
    setPatientsList,
    selectedUser,
    updateHistoryMessages,
    updateMessages,
    setIsFetchingChats,
    setTotalMessagesCountInChat,
  } = useChat();

  const leaveGroup = useCallback(
    (groupId: string) => {
      if (socketInstance?.socket) {
        // Emit the leave_group event
        socketInstance?.socket.emit('leave_group', { group_id: groupId });
        currentGroupID.current = null;
        if (localStorage.getItem('lastGroupIdBeforePageUnload')) {
          localStorage.removeItem('lastGroupIdBeforePageUnload');
        }
      }
    },

    [socketInstance?.socket]
  );

  const enterGroup = useCallback(
    (groupId: string) => {
      if (socketInstance?.socket) {
        socketInstance?.socket.emit('enter_group', { group_id: groupId });
        currentGroupID.current = groupId;
      }
    },
    [socketInstance?.socket]
  );

  const getPatientsList = useCallback(
    ({
      size,
      page,
      silentFetch,
      search = '',
    }: {
      size: number;
      page?: number;
      silentFetch?: boolean;
      search?: string;
    }) => {
      if (!silentFetch) {
        setIsFetchingPatients(true);
      }

      if (socketInstance?.socket) {
        socketInstance?.socket?.emit('users', {
          size,
          page: page || 1,
          search,
        });
      }
    },

    [socketInstance?.socket]
  );

  useEffect(() => {
    if (!socketInstance?.socket) return;

    if (socketInstance?.socket) {
      setConnection(true);
      if (currentGroupID.current) {
        enterGroup(currentGroupID.current);
      }
    } else {
      setConnection(false);

      return;
    }

    socketInstance?.socket?.on('message', (data) => {
      if (
        location.pathname === chatAllowedPathname &&
        currentUserRef.current?.groupId === data.group_id
      ) {
        if ('message_id' in data) {
          updateMessages({
            message: data.message,
            mid: data.message_id,
            groupId: data.group_id || '',
            isIncoming: data.provider !== data.sent_by,
            isOutgoing: data.provider === data.sent_by,
            timeStamp: data.timestamp,
          });
        }
      } else if (location.pathname === chatAllowedPathname) {
        getPatientsList({ size: 30, silentFetch: true });
      }
    });

    socketInstance?.socket?.on('users', (data: UsersType | UsersEmitType) => {
      if ('items' in data) {
        setPatientsList(getUsersObjectInCamelCase(data.items));
        setIsFetchingPatients(false);
      }
    });

    socketInstance?.socket?.on('messages_history', (data) => {
      updateHistoryMessages(data.items);
      setTotalMessagesCountInChat(data.count);
      setIsFetchingChats(false);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      unmountHandler();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketInstance?.socket]);

  console.log(connection);

  const unmountHandler = async () => {
    if (selectedUser.groupId) {
      await leaveGroup(selectedUser.groupId);
    }
  };

  const sendMessage = useCallback(
    async (message: string, groupId: string) => {
      if (socketInstance?.socket) {
        socketInstance?.socket.emit('message', {
          group_id: groupId,
          message,
        });
        updateMessages({
          message,
          mid: Math.random().toString(),
          groupId: selectedUser.groupId || '',
          isOutgoing: true,
          isIncoming: false,
          timeStamp: new Date().toISOString(),
        });
      } else {
        console.error('Socket is not connected or groupId is missing');
        toast.error(
          'Unable to send the message. Please refresh the page and try again.'
        );
        await socketInstance?.reconnectSocket();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketInstance?.socket]
  );

  const openChatHistory = useCallback(
    async ({
      groupId,
      size,
      silentFetch,
    }: {
      groupId: string;
      size?: number;
      silentFetch?: boolean;
    }) => {
      if (!silentFetch) {
        setIsFetchingChats(true);
      }
      if (socketInstance?.socket) {
        socketInstance.socket.emit('messages_history', {
          group_id: groupId,
          page: 1,
          size: size || 20,
        });
      } else {
        toast.error('Could not load the message history');
        setIsFetchingChats(false);
        await socketInstance?.reconnectSocket();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketInstance?.socket]
  );

  useEffect(() => {
    if (selectedUser.groupId) {
      currentUserRef.current = selectedUser;
    }
  }, [selectedUser]);

  return {
    connection,

    sendMessage,
    getPatientsList,
    enterGroup,
    openChatHistory,
    leaveGroup,
    isFetchingPatients,
  };
};
