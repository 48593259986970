import { useCallback, useEffect, useRef, useState } from 'react';

import { format, isToday } from 'date-fns';

import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { useProfile } from 'hooks/useProfile';
import { ChatUsersListObject, MessageObject } from 'types/socket/types';
import { getUsersTimeFromUTC } from 'utils/common';

import { MessagesContainerSkeleton } from '../MessagesContainerSkeleton/MessagesContainerSkeleton';
import { NoChatScreen } from '../NoChatsScreen/NoChatsScreen';

interface ChatMessageListProps {
  chats: Array<MessageObject>;
  loadMoreMessages: () => void;
  selectedPatient: ChatUsersListObject;
  isLoading: boolean;
  isLastMessageInChat: boolean;
}

export function ChatMessageList({
  chats,
  loadMoreMessages,
  selectedPatient,
  isLoading,
  isLastMessageInChat,
}: ChatMessageListProps) {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  // const [isAtBottom, setIsAtBottom] = useState(true);
  const [prevChatLength, setPrevChatLength] = useState(chats.length);

  const profile = useProfile();
  const providerProfilePicture = profile.profile?.pictureUrl;
  const providerName = profile.profile?.firstName;
  const patientProfilePicture = selectedPatient.profilePicture;
  const [isLoadingOldMessages, setIsLoadingOldMessages] = useState(false);
  // const [isScrolling, setIsScrollingToBottom] = useState(false);

  const scrollToBottom = useCallback(() => {
    if (chatEndRef.current && !isLoadingOldMessages) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
      // setIsAtBottom(true);
      // setIsScrollingToBottom(false);
    }
  }, [isLoadingOldMessages]);

  const handleScroll = useCallback(() => {
    if (chatContainerRef.current) {
      const { scrollTop } = chatContainerRef.current;

      // Detect if the user is at the bottom of the chat
      // const isUserAtBottom = scrollHeight - scrollTop === clientHeight;
      // setIsAtBottom(isUserAtBottom);

      if (scrollTop === 0 && !isLoadingOldMessages && chats.length > 10) {
        loadMoreMessages(); // Load more messages if scrolled to top
        setIsLoadingOldMessages(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreMessages, isLoadingOldMessages]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);

      return () => {
        chatContainer.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll]);

  useEffect(() => {
    if (chats.length > 0 && prevChatLength === 0) {
      scrollToBottom();
      // setIsScrollingToBottom(true);
    }
    setIsLoadingOldMessages(false);
    setPrevChatLength(chats.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats.length]);

  useEffect(() => {
    if (chats.length && !isLoadingOldMessages && prevChatLength !== 0) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats.length]);

  return (
    <div
      ref={chatContainerRef}
      className="custom-scrollbar flex flex-grow flex-col overflow-y-auto">
      {isLoadingOldMessages ? (
        <div className="mt-2 flex justify-center">
          <LoadingSpinner className="text-primary-main" />
        </div>
      ) : null}

      <div className="flex flex-grow flex-col justify-end">
        {isLastMessageInChat ? (
          <div className="mt-3 flex justify-center">
            <Typography variant="subtitle2" className="w-100 mb-1 text-center">
              This is the start of your conversation with{' '}
              {selectedPatient.patientName}
            </Typography>
          </div>
        ) : null}

        {chats.length ? (
          chats.map((msg, index) => {
            const date = getUsersTimeFromUTC(msg.timeStamp);
            const showDate =
              index === 0 ||
              getUsersTimeFromUTC(chats[index - 1]?.timeStamp)
                ?.localDateOnly !== date?.localDateOnly;
            const { isOutgoing } = msg;

            return (
              <>
                {showDate ? (
                  <div className="my-4 flex justify-center">
                    <Typography
                      variant="subtitle2"
                      className="rounded-full bg-[#494949] p-2 px-4">
                      {isToday(new Date(date?.localDateOnly || ''))
                        ? 'Today'
                        : format(
                            new Date(date?.localDateOnly || ''),
                            'MM-dd-yyyy'
                          )}
                    </Typography>
                  </div>
                ) : null}

                <div
                  key={msg.mid}
                  className={`mb-4 flex w-full flex-col justify-end p-3 ${
                    isOutgoing ? 'items-end' : 'items-start'
                  }`}>
                  <div
                    className={`flex items-end p-1 ${
                      !isOutgoing ? 'flex-row' : 'flex-row-reverse'
                    }`}>
                    <Avatar
                      imgUrl={
                        isOutgoing
                          ? providerProfilePicture
                          : patientProfilePicture || ''
                      }
                      userName={
                        isOutgoing
                          ? providerName
                          : selectedPatient.patientName || ''
                      }
                      sizeClass={`w-6 h-6 ${isOutgoing ? 'ml-3' : 'mr-3'}`}
                    />
                    <div
                      className={`rounded-t-2xl ${
                        isOutgoing ? 'rounded-bl-2xl' : 'rounded-br-2xl'
                      } ${
                        isOutgoing ? 'bg-primary-main' : 'bg-[#494949]'
                      } p-3`}>
                      <Typography
                        variant="subtitle2"
                        color="white"
                        className="whitespace-pre-wrap break-all">
                        {msg.message}
                      </Typography>
                      <div className="mt-2 flex justify-end">
                        <Typography color="white" variant="body2">
                          {date?.localTimeOnly || ''}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : isLoading && chats.length === 0 ? (
          <MessagesContainerSkeleton />
        ) : !isLoading && chats.length === 0 ? (
          <div className="flex flex-1 flex-row justify-center text-center text-gray-500">
            <NoChatScreen />
          </div>
        ) : null}
      </div>
      <div ref={chatEndRef} />
    </div>
  );
}
