// import { useState } from 'react';

// eslint-disable-next-line import/extensions

import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

// eslint-disable-next-line import/extensions
import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { WarningText } from 'components/common/WarningText/WarningText';
import { ChatUsersListObject } from 'types/socket/types';
// import { useChat } from 'context/ChatProvider';

type ChatInputProps = {
  setInputText: (message: string) => void;
  isMessagingAllowed: boolean;
  selectedUser: ChatUsersListObject;
};

export function ChatInput({
  setInputText,
  isMessagingAllowed,
  selectedUser,
}: ChatInputProps) {
  const { control, reset, handleSubmit, setValue, getValues } = useForm();

  const handleSendMessage = handleSubmit(async (data) => {
    if (!data.messageInput.trim()) return;
    setInputText(data.messageInput);
    reset({ messageInput: '' });
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault();
        setValue('messageInput', `${getValues('messageInput')}\n`);
      } else {
        event.preventDefault();
        handleSendMessage();
        setValue('messageInput', '');
      }
    }
  };

  useEffect(() => {
    reset({ messageInput: '' });
  }, [reset, selectedUser.groupId]);

  return (
    <div className=" border-t border-black p-4 pt-6">
      {!isMessagingAllowed ? (
        <WarningText>
          <div>
            You won’t be able to send messages to this patient because your
            patient is archived.
          </div>
        </WarningText>
      ) : (
        <>
          <TextInput
            fullWidth
            onKeyDown={handleKeyDown}
            disabled={!isMessagingAllowed}
            name="messageInput"
            type="text"
            multiline
            rows={2}
            control={control}
            placeholder="Type your message..."
            className="custom-scrollbar -mb-4 w-full resize-none rounded-lg border border-gray-300"
          />
          <Typography variant="subtitle3" className="-mt-1 text-zinc-400">
            Press Enter to send the message, and Shift + Enter to insert a new
            line.
          </Typography>
        </>
      )}
    </div>
  );
}
