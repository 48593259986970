/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';

import {
  ChatUsersListObject,
  MessageObject,
  SocketMethodsType,
  SocketResponseMessageObject,
} from 'types/socket/types';
import { convertChatHistoryObject } from 'utils/sockets/helper';

type Chats = MessageObject[];
type user = ChatUsersListObject;

type ChatProviderTypes = {
  selectedUser: user;
  setSelectedUser: React.Dispatch<React.SetStateAction<user>>;
  setSocketMethods: React.Dispatch<
    React.SetStateAction<SocketMethodsType | null>
  >;
  socketMethods?: SocketMethodsType | null;
  isFetchingChats: boolean;
  chats: Chats;
  updateMessages: (chatObj: MessageObject) => void;
  resetChats: () => void;
  patientsList: user[];
  setPatientsList: React.Dispatch<React.SetStateAction<user[]>>;
  setIsFetchingChats: React.Dispatch<React.SetStateAction<boolean>>;
  updateHistoryMessages: (data: SocketResponseMessageObject[]) => void;
  totalMessagesCountInChat: number;
  setTotalMessagesCountInChat: React.Dispatch<React.SetStateAction<number>>;
};

const defaultUser: ChatUsersListObject = {
  patientId: '',
  patientName: '',
  groupId: '',
  groupName: '',
  unreadMessagesCount: 0,
  profilePicture: '',
  lastMessage: '',
  isPatientActive: true,
};

const defaultChatContextValues: ChatProviderTypes = {
  selectedUser: defaultUser,
  setSelectedUser: () => {},
  updateMessages: () => {},
  updateHistoryMessages: () => {},
  resetChats: () => {},
  socketMethods: null,
  setSocketMethods: () => {},
  isFetchingChats: false,
  chats: [],
  patientsList: [],
  setPatientsList: () => {},
  setIsFetchingChats: () => {},
  totalMessagesCountInChat: 0,
  setTotalMessagesCountInChat: () => {},
};

const ChatContext = createContext(defaultChatContextValues);

export function ChatProvider({ children }: { children: ReactNode }) {
  const [selectedUser, setSelectedUser] = useState<user>(defaultUser);
  const [patientsList, setPatientsList] = useState<user[]>([]);
  const [isFetchingChats, setIsFetchingChats] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [socketMethods, setSocketMethods] = useState<any | undefined>();
  const [totalMessagesCountInChat, setTotalMessagesCountInChat] = useState(0);

  const [chats, setChats] = useState<Chats>([]);

  const updateMessages = useCallback((chatObj: MessageObject) => {
    if (chatObj) {
      setChats((prevMessages) => {
        // Check if message already exists based on some unique identifier (e.g., message ID)
        if (prevMessages.some((msg) => msg.mid === chatObj.mid)) {
          return prevMessages;
        }

        return [...prevMessages, chatObj];
      });
    }
  }, []);

  const updateHistoryMessages = useCallback(
    (data: SocketResponseMessageObject[]) => {
      let tempMessages = [];
      tempMessages = convertChatHistoryObject(data);
      setChats(tempMessages);
    },
    []
  );

  const resetChats = () => {
    setChats([]);
  };

  const values = useMemo(
    () => ({
      selectedUser,
      chats,
      setSelectedUser,
      updateMessages,
      resetChats,
      setPatientsList,
      patientsList,
      updateHistoryMessages,
      setSocketMethods,
      socketMethods,
      setIsFetchingChats,
      isFetchingChats,
      setTotalMessagesCountInChat,
      totalMessagesCountInChat,
    }),
    [
      selectedUser,
      chats,
      updateMessages,
      patientsList,
      updateHistoryMessages,
      socketMethods,
      setSocketMethods,
      isFetchingChats,
      totalMessagesCountInChat,
    ]
  );

  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
}

export const useChat = () => useContext(ChatContext);
