import React, { Suspense, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useOutletContext } from 'react-router-dom';

import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { useCalculateDifference } from 'hooks/e-prescribe/useCalculateDifference';
import { useCheckPrescription } from 'hooks/e-prescribe/useCheckPrescription';
import { useCurrentActivePrescription } from 'hooks/e-prescribe/useCurrentPrescription';
import { useHormoneStandardizedName } from 'hooks/e-prescribe/useHormoneStandardizedName';
import { useProgesteroneController } from 'hooks/hormones-new/useProgesteroneController';
import { PatientPrescriptionService } from 'services/prescription';
import {
  HormoneSelectionProps,
  PatientDetailsContextType,
} from 'types/patient';
import {
  HormoneNames,
  HormoneValidations,
  PrescribedObjectType,
  PrescriptionCheckValuesForReview,
  PrescriptionDifferenceType,
  PrescriptionDisplayTypes,
  PrescriptionInputTypes,
} from 'types/prescriptionTypes';
import { capitalizedString } from 'utils/common';

import { colorObjForCheckPrescription, colorObjForSupply } from '../common';
import { HormoneDetailsUIV2 } from '../HormoneDetailsUIV2';

export function Progesterone({
  addNewPrescription,
  RxDetails,
  onBack,
}: HormoneSelectionProps) {
  const [maximumDose, setMaximumDose] = useState(0);
  const { hasDeaForShippingState } =
    useOutletContext<PatientDetailsContextType>();

  const {
    ProgesteroneDetails,

    dtd,
    dose,

    handleConfirmation,
    refills,
    setRefills,
    openWarningPopup,
    setDTD,
    setOpenWarningPopup,
    currentDose,
    setCurrentDose,
    maxDoseOptions,
    setDose,
  } = useProgesteroneController();

  const getCurrentPrescriptionCheckMutation = useMutation(
    (id: string) => PatientPrescriptionService.getPrescriptionCheckValue(id),
    {
      onSuccess() {
        // console.log(data, '******');
      },
    }
  );

  function handleDose(val: number, type?: string) {
    if (!type) {
      setDose(val);

      return;
    }

    if (type && typeof maxDoseOptions[val] === 'string') {
      setOpenWarningPopup(true);
    }
  }

  const {
    currentActivePrescriptions,
    isLoading: currentPrescriptionFetchLoading,
  } = useCurrentActivePrescription({
    medicationId: RxDetails?.id ?? '',
    onSuccess(prescription) {
      getCurrentPrescriptionCheckMutation.mutateAsync(prescription?.id ?? '');
    },
  });

  const { calculatePercentageDifference, getDifferenceUI } =
    useCalculateDifference({
      hormoneName: HormoneNames.PROGESTERONE,
    });
  const [sig, setSig] = useState('');

  useEffect(() => {
    setSig(
      ProgesteroneDetails.dose[dose] === maxDoseOptions[maximumDose]
        ? `Apply ${ProgesteroneDetails.dose[dose]} ml per day topically`
        : `Apply ${ProgesteroneDetails.dose[dose]} ml per day topically and increase as directed up to ${maxDoseOptions[maximumDose]} ml per day topically`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dose, maximumDose]);

  const prescriptionObject: PrescribedObjectType = useMemo(
    () => ({
      id: RxDetails?.id,
      displayName: ProgesteroneDetails.displayName,
      name: HormoneNames.PROGESTERONE,
      strength: ProgesteroneDetails?.strength,
      strengthUnit: ProgesteroneDetails.strengthUnit,

      ratioUnit: ProgesteroneDetails.ratioUnit,
      dose: maxDoseOptions[maximumDose],
      minimumDose: ProgesteroneDetails.dose[dose],
      doseUnit: ProgesteroneDetails.doseUnit,
      refills,
      dtd,
      sig,
      deliveryMethod: ProgesteroneDetails.deliveryMethod,
      family: ProgesteroneDetails.family,
      coveredUnderSubscription:
        RxDetails?.coveredUnderSubscription?.toString() ?? 'false',
      dtdUnit: ProgesteroneDetails.dtdUnit,
      currentDose,
    }),
    [
      ProgesteroneDetails,
      RxDetails,
      dose,
      dtd,
      sig,
      maximumDose,
      refills,
      currentDose,
      maxDoseOptions,
    ]
  );

  const { checkPrescriptionValueQuery: maxDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose:
          typeof maxDoseOptions[maximumDose] === 'number'
            ? maxDoseOptions[maximumDose]
            : maxDoseOptions[maximumDose - 1],
      },
      onSuccess() {
        // const a = data.value.split('/');
        // if (Number(a[0]) > 4.41) {
        //   setStrength('24/160');
        //   if (strength !== '24/160') {
        //     // renderModal(strength.toString(), '24/160', 'mg/day');
        //   }
        // } else {
        //   setStrength('8/160');
        //   if (strength !== '8/160') {
        //     // renderModal(strength.toString(), '8/160', 'mg/day');
        //   }
        // }
      },
    });

  const { checkPrescriptionValueQuery: minDosePrescriptionValues } =
    useCheckPrescription({
      prescription: {
        ...prescriptionObject,
        dose: ProgesteroneDetails.dose[dose],
      },
      onSuccess() {
        // console.log(data);
      },
    });

  const { getStandardizedNameQuery } = useHormoneStandardizedName({
    prescription: {
      ...prescriptionObject,
      dose: ProgesteroneDetails.dose[dose],
    },
  });

  const prescriptionCheckValues: PrescriptionCheckValuesForReview = useMemo(
    () => ({
      checkValues: {
        withMaxDose: maxDosePrescriptionValues.data?.data.value,
        withMinDose: minDosePrescriptionValues.data?.data.value,
        unit: maxDosePrescriptionValues.data?.data.unit ?? '',
      },
      daysOfSupply: {
        withMaxDose:
          maxDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
        withMinDose:
          minDosePrescriptionValues.data?.data.daysOfSupply?.toString(),
      },
      isHighPotencyRx:
        Number(maxDosePrescriptionValues.data?.data.value) >= 310,
      standardizedName:
        getStandardizedNameQuery.data?.data.standardRepresentation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      maxDosePrescriptionValues,
      minDosePrescriptionValues,
      getStandardizedNameQuery.data?.data.standardRepresentation,
    ]
  );

  function getComparisonValueForMinimumDose() {
    if (
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value
    ) {
      const comparableValue =
        getCurrentPrescriptionCheckMutation.data?.data?.currentDoseQuality
          ?.value ??
        getCurrentPrescriptionCheckMutation.data?.data?.minDoseQuality?.value;

      return getDifferenceUI(
        calculatePercentageDifference(
          comparableValue,
          minDosePrescriptionValues.data?.data.value
        ),
        `${comparableValue} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
      );
    }

    return '';
  }

  const maxDoseDiff = useMemo(
    () =>
      currentActivePrescriptions?.id &&
      !!getCurrentPrescriptionCheckMutation.data?.data?.value
        ? calculatePercentageDifference(
            getCurrentPrescriptionCheckMutation.data?.data?.value,
            maxDosePrescriptionValues.data?.data.value
          )
        : 0,
    [
      getCurrentPrescriptionCheckMutation.data?.data,
      maxDosePrescriptionValues.data?.data,
      calculatePercentageDifference,
      currentActivePrescriptions?.id,
    ]
  );

  const prescriptionDifferenceValues: PrescriptionDifferenceType = useMemo(
    () => ({
      maxDoseDiffValue: maxDoseDiff,
    }),
    [maxDoseDiff]
  );

  useEffect(() => {
    if (maxDoseOptions[maximumDose] >= 0.4) {
      setDTD(60);
    } else {
      setDTD(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dose, maximumDose]);

  useEffect(() => {
    if (currentActivePrescriptions?.id) {
      getCurrentPrescriptionCheckMutation.mutateAsync(
        currentActivePrescriptions?.id ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions?.id]);

  useEffect(() => {
    if (dose > 27) {
      setMaximumDose(0);
    }
  }, [dose]);

  useEffect(() => {
    if (
      currentActivePrescriptions?.id &&
      !currentActivePrescriptions?.titrationType
    ) {
      setDose(
        ProgesteroneDetails.dose.findIndex(
          (d: number) =>
            d ===
            Number(
              currentActivePrescriptions?.deliveryMethodAndSig.minDailyDose
                .value
            )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions]);

  useEffect(() => {
    if (
      currentActivePrescriptions?.id &&
      !currentActivePrescriptions?.titrationType &&
      Number(
        currentActivePrescriptions?.deliveryMethodAndSig?.minDailyDose?.value
      ) < 1.5
    ) {
      setMaximumDose(
        maxDoseOptions.findIndex(
          (d: number) =>
            d ===
            Number(
              currentActivePrescriptions?.deliveryMethodAndSig?.dose?.value
            )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActivePrescriptions]);

  return (
    <>
      <HormoneDetailsUIV2
        onAdd={() =>
          addNewPrescription({
            ...prescriptionObject,
            ...prescriptionCheckValues,
          })
        }
        prescriptionObject={{
          ...prescriptionObject,
          ...prescriptionCheckValues,
          ...prescriptionDifferenceValues,
        }}
        validations={{
          [HormoneValidations.Loading]: {
            isFailed:
              maxDosePrescriptionValues.isLoading ||
              minDosePrescriptionValues.isLoading ||
              getStandardizedNameQuery.isLoading,
          },
          [HormoneValidations.MissingDEA]: {
            isFailed:
              !hasDeaForShippingState && !!RxDetails?.isControlledSubstance,
          },
          [HormoneValidations.DaysOfSupply]: {
            isFailed: !maxDosePrescriptionValues.data?.data.validDaysOfSupply,
          },
          [HormoneValidations.SIGExceed140Chars]: {
            isFailed: Number(sig.length) > 140,
          },
        }}
        onBack={onBack}
        title={capitalizedString(RxDetails?.name)}
        hormoneInputs={[
          {
            containerClassName: 'flex justify-between',
            title: '',
            children: [
              {
                type: PrescriptionDisplayTypes.InfoBox,
                isLoading: false,
                value: `${ProgesteroneDetails?.strength} ${ProgesteroneDetails.strengthUnit}`,
                label: 'Strength',
              },
              {
                type: PrescriptionDisplayTypes.InfoBox,
                isLoading: false,
                value: `${dtd.toString()} ml`,
                label: 'DTD',
              },
              {
                type: PrescriptionDisplayTypes.InfoBox,
                isLoading: false,
                value: ProgesteroneDetails.deliveryMethod,
                label: 'Delivery Method',
              },
              {
                label: 'Days of Supply',
                type: PrescriptionDisplayTypes.InfoBox,
                isLoading:
                  !!maxDosePrescriptionValues.isLoading ||
                  !!minDosePrescriptionValues.isLoading,
                value:
                  maxDosePrescriptionValues.data?.data.daysOfSupply ===
                  minDosePrescriptionValues.data?.data?.daysOfSupply
                    ? `${maxDosePrescriptionValues.data?.data.daysOfSupply} Days`
                    : `${maxDosePrescriptionValues.data?.data.daysOfSupply}-${minDosePrescriptionValues.data?.data?.daysOfSupply} Days`,

                colorClass: `${
                  maxDosePrescriptionValues.data?.data.rxQuality
                    ? colorObjForSupply[
                        maxDosePrescriptionValues.data?.data.validDaysOfSupply.toString()
                      ]
                    : ''
                }`,
              },
            ],
          },

          {
            title: 'Dose',
            containerClassName:
              'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
            children: [
              {
                type: PrescriptionInputTypes.Slider,
                getTooltip: (val) =>
                  `${`${Number(ProgesteroneDetails.dose[val]).toFixed(2)} ${
                    ProgesteroneDetails.doseUnit
                  }`}`,
                onChange: handleDose,
                options: [
                  ...ProgesteroneDetails.dose.slice(
                    0,
                    ProgesteroneDetails.dose.length - 1
                  ),
                ],
                value: dose,
                wrapperClassName: 'col-span-9',
              },
              {
                type: PrescriptionDisplayTypes.InfoBox,
                isLoading:
                  !!minDosePrescriptionValues.isLoading ||
                  getCurrentPrescriptionCheckMutation.isLoading ||
                  currentPrescriptionFetchLoading,
                label: `Minimum ${minDosePrescriptionValues?.data?.data.unit}`,
                value:
                  `${minDosePrescriptionValues?.data?.data.value} ${minDosePrescriptionValues?.data?.data.unit}` ??
                  '',
                wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
                colorClass: `${
                  minDosePrescriptionValues.data?.data.rxQuality
                    ? colorObjForCheckPrescription[
                        minDosePrescriptionValues.data?.data.rxQuality
                      ]
                    : ''
                }`,
                helperText: getComparisonValueForMinimumDose(),
              },
              {
                type: PrescriptionInputTypes.DoseSplitter,
                onChange: (cd) => {
                  setCurrentDose({
                    morning: {
                      value: cd.morning,
                      unit: ProgesteroneDetails.doseUnit,
                    },
                    evening: {
                      value: cd.evening,
                      unit: ProgesteroneDetails.doseUnit,
                    },
                  });
                },
                hormoneName: HormoneNames.PROGESTERONE,
                value: ProgesteroneDetails.dose[dose],
                wrapperClassName: 'col-span-9 pl-4 mt-16 ',
              },
            ],
          },
          {
            title: 'Maximum Dose',
            containerClassName:
              'grid grid-cols-12 p-10 pb-10 pt-6 bg-background-main rounded items-center gap-x-20',
            children: [
              {
                type: PrescriptionInputTypes.Slider,
                getTooltip: (val) =>
                  `${
                    typeof maxDoseOptions[val] === 'number'
                      ? `${Number(maxDoseOptions[val])?.toFixed(2)} ${
                          ProgesteroneDetails.doseUnit
                        }`
                      : '2< ml/day'
                  }`,
                onChange: (d) => {
                  // setMaxDosePreValue(maxDoseOptions[d]);
                  setMaximumDose(d);
                  handleDose(d, 'maximumDose');
                },
                options: maxDoseOptions,
                value: maximumDose,
                wrapperClassName: 'col-span-9',
              },
              {
                type: PrescriptionDisplayTypes.InfoBox,
                isLoading:
                  !!maxDosePrescriptionValues.isLoading ||
                  getCurrentPrescriptionCheckMutation.isLoading ||
                  currentPrescriptionFetchLoading,
                label: `Maximum ${maxDosePrescriptionValues?.data?.data.unit}`,
                value:
                  `${maxDosePrescriptionValues?.data?.data.value} ${maxDosePrescriptionValues?.data?.data.unit}` ??
                  '',
                wrapperClassName: 'col-span-3 !h-[7rem] mt-6  ',
                colorClass: `${
                  maxDosePrescriptionValues.data?.data.rxQuality
                    ? colorObjForCheckPrescription[
                        maxDosePrescriptionValues.data?.data.rxQuality
                      ]
                    : ''
                }`,
                helperText:
                  currentActivePrescriptions?.id &&
                  !!getCurrentPrescriptionCheckMutation.data?.data?.value
                    ? getDifferenceUI(
                        calculatePercentageDifference(
                          getCurrentPrescriptionCheckMutation.data?.data?.value,
                          maxDosePrescriptionValues.data?.data.value
                        ),
                        `${getCurrentPrescriptionCheckMutation.data?.data?.value} ${getCurrentPrescriptionCheckMutation.data?.data?.unit}`
                      )
                    : '',
              },
            ],
          },
          {
            title: 'Refills',
            containerClassName:
              'grid grid-cols-12 p-10 pb-16 pt-20 bg-background-main rounded items-center gap-x-20',
            children: [
              {
                type: PrescriptionInputTypes.Slider,
                getTooltip: (val) =>
                  `${ProgesteroneDetails.refills[val]}  refills`,
                onChange: (d) => {
                  setRefills(d);
                },
                options: ProgesteroneDetails.refills,
                value: refills,
                wrapperClassName: 'col-span-9',
              },
            ],
          },
          {
            containerClassName: '',
            title: 'SIG',
            children: [
              {
                type: PrescriptionInputTypes.SIG,
                wrapperClassName: 'col-span-9',
                onChange: (s) => {
                  setSig(s);
                },
                value: sig,
              },
            ],
          },
        ]}
      />
      <Suspense>
        <ConfirmationModal
          classes={{
            modal: 'w-[50rem]',
          }}
          isModalOpen={openWarningPopup}
          title="The maximum daily dose for topical progesterone is limited to 2.0 ml per day. If a higher dose is required, please switch to Oral Micronized Progesterone."
          confirmText="Confirm"
          handleConfirm={handleConfirmation}
          closeModal={(close) => {
            setOpenWarningPopup(!close);

            setMaximumDose(maximumDose - 1);
            setDTD(60);
          }}
        />
      </Suspense>
    </>
  );
}
