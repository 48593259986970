import { useChat } from 'context/ChatProvider';

import { ChatInput } from '../ChatInput/ChatInput';

export function ChatFooter() {
  const { selectedUser, socketMethods } = useChat();

  //   const [message, setMessage] = useState('');

  const handleSendMessage = (message: string) => {
    socketMethods?.sendMessage(message, selectedUser.groupId || '');
  };

  return (
    <ChatInput
      selectedUser={selectedUser}
      setInputText={handleSendMessage}
      isMessagingAllowed={selectedUser.isPatientActive}
    />
  );
}
