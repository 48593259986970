import classNames from 'classnames';

import { LoadingSpinner } from '../Loading/LoadingSpinner';

interface CheckBoxProps {
  id: string;
  label: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  setRef?: (ref: HTMLInputElement | null) => void;
  disable?: boolean;
  isLoading?: boolean;
  bgColor?: string;
}

export function CheckBox({
  id,
  onChange,
  className,
  label,
  checked,
  setRef,
  disable,
  isLoading,
  bgColor,
  ...rest
}: CheckBoxProps) {
  return (
    <div className={classNames(' flex items-center text-md   ', className)}>
      {isLoading ? (
        <LoadingSpinner
          style={{
            width: 20,
            height: 20,
            color: 'white',
          }}
        />
      ) : (
        <input
          {...rest}
          ref={(ref) => setRef?.(ref)}
          className={classNames(
            'h-6 w-4  cursor-pointer border accent-primary-main  ',
            {
              '!cursor-not-allowed': disable,
            },
            bgColor
          )}
          id={id}
          onChange={
            disable
              ? undefined
              : (e) => {
                  onChange(e.target.checked);
                }
          }
          type="checkbox"
          checked={checked}
        />
      )}
      <span className="mr-2" />
      <label className="text-md text-background-contrastText" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
